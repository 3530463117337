import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { newsData } from '../constants/newsData';

const NewsDetailPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const newsItem = newsData.find((news) => news.id === parseInt(id));

  useEffect(() => {
    if (!newsItem) {
      navigate('/');
    }
  }, [newsItem, navigate]);

  if (!newsItem) {
    return null;
  }

  return (
    <div className="w-full mx-auto bg-[#F1F4F9]">
      <div className="max-w-[1200px] w-[1180px] mx-auto">
        {/* 상단 섹션 */}
        <div className="relative flex items-center gap-6 absolute pt-8 mb-8">
          <div className="relative w-[48px] h-[48px]">
            {/* 왼쪽 화살표 아이콘 */}
            <button
              className="absolute left-0 top-1/2 transform -translate-y-1/2 text-black rounded-full"
              style={{ zoom: 2 }}
              onClick={() => navigate(-1)}
            >
              &lt;
            </button>
          </div>
          {/* 제목 및 날짜 */}
          <div className="flex flex-col">
            <h1 className="text-black text-[36px] font-bold uppercase">{newsItem.title}</h1>
            <p className="text-black text-[20px] font-light">{newsItem.date}</p>
          </div>
        </div>

        {/* 이미지 섹션 */}
        <div className="relative w-[1196px] h-[312px] mx-auto">
          <img src={newsItem.image} alt="News" className="absolute w-full h-full object-contain" />
        </div>

        {/* 뉴스 내용 섹션 */}
        <div className="flex flex-col gap-6 w-[1198px] mx-auto mt-[24px]">
          <p className="text-black text-[20px] font-light">{newsItem.title_content}</p>

          {Object.entries(newsItem.content).map(([key, value], index, array) => {
            if (key.startsWith('sub_title')) {
              const contentKey = `sub_content_${key.split('_')[2]}`;
              const contentValue = array.find(([k]) => k === contentKey)?.[1];

              return (
                <React.Fragment key={key}>
                  <h2 className="text-black text-[24px] font-bold uppercase">{value}</h2>
                  <p className="text-black text-[20px] font-light">{contentValue}</p>
                </React.Fragment>
              );
            }
            return null;
          })}
        </div>

        {/* 관련 게시물 섹션 */}
        <div className="flex flex-col gap-10 w-[1198px] mx-auto mt-[60px]">
          <h3 className="text-black text-[36px] font-bold uppercase">관련 게시물</h3>

          <div className="flex flex-row gap-10 flex-wrap pb-12">
            {/* 관련 뉴스 아이템 반복 */}
            {newsData
              .filter((relatedNews) => relatedNews.id !== parseInt(id))
              .slice(0, 6)
              .map((relatedNews) => (
                <div
                  key={relatedNews.id}
                  className="w-[372px] bg-white shadow-md cursor-pointer"
                  onClick={() => {
                    navigate(`/newsdetail/${relatedNews.id}`);
                    window.scrollTo(0, 0);
                  }}
                >
                  <div className="w-[372px] h-[282px] overflow-hidden">
                    <div
                      className="w-full h-full bg-cover bg-center transform transition-transform duration-500 hover:scale-125"
                      style={{ backgroundImage: `url(${relatedNews.image})` }}
                    ></div>
                  </div>
                  <div className="p-6">
                    <h3 className="text-black text-[20px] font-bold uppercase mb-4">{relatedNews.title}</h3>
                    <p className="text-black text-[14px]">{relatedNews.title_content}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsDetailPage;
